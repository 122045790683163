import styles from '../../styles/Market.module.css';
import {Market1,Market2} from '../../media/images';

export default function Market() {
    return (
        <div className={styles.MARKET}>
            <LeftSide/>
            <RightSide/>
        </div>
    )
}
function LeftSide() {
    return (
        <div className={styles.Container}>
            <img src={Market1} alt="Market one"/>
        </div>
    );
}
function RightSide() {
    return (
        <div className={styles.Container}>
            <img src={Market2} alt="Market two"/>
        </div>
    )
}