import styles from '../styles/Footer.module.css'
import {Logo} from "../media/images";
import {css} from "@emotion/css";
import {useContext} from "react";
import {LanguageContext} from "../../context";
import {FooterEN, FooterID} from "../data/LandingPageData";
let props = {
    WA: "",
    Instagram: "",
    Email: ""
}

export default function Footer() {
    const language = useContext(LanguageContext)
    if (language === "en-US")
        props = FooterEN
    else
        props = FooterID
    return (
        <div className={styles.Footer}>
            <LeftFooter/>
            <RightFooter/>
        </div>
    )
}
function LeftFooter() {
    return (
        <div className={styles.leftContainer}>
            <img src={Logo} alt={"Logo"} className={styles.FooterLogo}/>
            <p className={styles.FooterText}>WE’RE LEVELING UP INDONESIA’S SNACKING SCENE</p>
            <p className={styles.FooterCopyright}>© 2023 Banka. All Rights Reserved. Privacy Policy. Terms of Use.</p>
        </div>
    )
}
function RightFooter() {
    return (
        <div className={styles.rightContainer}>
            <a href="https://api.whatsapp.com/send/?phone=%2B6282229637244&text&type=phone_number&app_absent=0"><img  src={props.WA} alt={"WA Button"}/></a>
            <a href="mailto:bankachips@gmail.com"><img src={props.Email} alt={"Email Button"}/></a>
            <a href="https://instagram.com/bankachips"><img src={props.Instagram} alt={"Instagram Button"}/></a>
        </div>
    )
}