import React, {useContext} from 'react'
import styles from '../../styles/BuyNowDivider.module.css'
import {BuyNowCTA} from '../../media/images'

export default function BuyNowDivider() {
    return (
        <div className={styles.inlineblock}>
            <a href="https://shopee.co.id/bankachips" target="_blank" className={styles.CTADIVIDER}>
                <img src={BuyNowCTA} alt={"Call to action"}/></a>
        </div>
    )
}