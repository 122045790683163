import styles from '../../styles/AboutDivider.module.css'
import {AdThree} from "../../media/images";
import {css} from "@emotion/css";
import {LanguageContext} from "../../../context";
import {AboutDividerID, AboutDividerEN} from "../../data/LandingPageData";
import {useContext} from "react";
let props = {
    contentA: "",
    contentB: ""
}

export default function AboutDivider() {
    const language = useContext(LanguageContext)
    if (language === "en-US")
        props = AboutDividerEN
    else
        props = AboutDividerID
    return (
        <div className={styles.AboutDividerContainer}>
            <img src={AdThree} alt={"Advertisement Three"}/>
            <p className={styles.AboutDividerText}>{props.contentA} <span className={css`color: #FFEE57`}>{props.contentB}</span></p>
        </div>
    )
}