import styles from '../../styles/WLC.module.css'
import {AdsMain1, AdsMain2} from "../../media/images";
export default function WLC() {
    return (
        <div className={styles.WLC}>
            <LeftSide/>
            <RightSide/>
        </div>
    )
}
function LeftSide() {
    return (
        <div className={styles.Container}>
            <img src={AdsMain1} alt="Advertisement pict one"/>
        </div>
    );
}
function RightSide() {
    return (
        <div className={styles.Container}>
            <img src={AdsMain2} alt="Advertisement pict two"/>
        </div>
    )
}