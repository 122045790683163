import styles from '../../styles/Simpleabout.module.css'
import {css} from "@emotion/css";
import {useContext} from "react";
import {LanguageContext} from "../../../context";
import {AboutID, AboutEN} from "../../data/LandingPageData";
let props = {
    content: ""
}

export default function About() {
    const language = useContext(LanguageContext)
    if (language === "en-US")
        props = AboutEN
    else
        props = AboutID
    return (
        <div className={styles.AboutShort}>
            <p className={styles.AboutText}>{props.content}</p>
        </div>
    )
}