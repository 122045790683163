import {Instagram, WA, Email,
        InstagramEN, WAEN, EmailEN,
        Certificates, CertificatesEN} from "../media/images";

export const HeaderEN = {
    cta: "Buy Now"
}
export const HeaderID = {
    cta: "Beli Sekarang"
}
export const JumboID = {
    value: "Kami menaikkan level cemilan Indonesia",
    description: "Ditaburi dengan keajaiban Indonesia, kami menggunakan pisang terbaik dari Pulau Bangka dan menggabungkan dengan rasa modern."
}
export const JumboEN = {
    value: "We are leveling up Indonesia’s snacking scene",
    description: "Topped off with a touch of Indonesian magic, we took our banana from the heart of Bangka Island and blend it with modern flavors",
}
export const JumboSecondID = {
    value: "Anugerah lokal dibuat di rumah",
    description: "Kami memaksimalkan waktu untuk membuat resep terbaik dan membubui keripik agar tercipta rasa tropis yang elegan.",
    certificates: Certificates
}
export const JumboSecondEN = {
    value: "Home-made w/ local fullfilment",
    description: "We craft and took the time to craft the best recipe, fry, and coat the chips to deliver the tropical taste.",
    certificates: CertificatesEN
}
export const AboutID =  {
    content: "Banka mulai dengan mimpi sederhana. Kami ingin memberikan cemilan hangat untuk Indonesia. Kami menggunakan kualitas bahan terbaik dan mengirim kebahagiaan untuk anda."
}
export const AboutEN = {
    content: "Banka started with a simple dream. We wanted to bring a heartwarming snack to Indonesia. We sourced the highest quality ingredients and deliver joy to you."
}
export const AboutDividerID = {
    contentA : "RASAKAN INDONESIA, DALAM ",
    contentB : "SATU GIGITAN"
}
export const AboutDividerEN  = {
    contentA: "TASTE INDONESIA, IN A ",
    contentB: "SINGLE BITE"
}
export const FooterID = {
    WA: WA,
    Instagram: Instagram,
    Email: Email
}
export const FooterEN = {
    WA: WAEN,
    Instagram: InstagramEN,
    Email: EmailEN
}
export const MarketID = {
    blueButtonContent: "Cari Tau Yuk",
}
export const MarketEN = {
    blueButtonContent: "Learn More!",
}
