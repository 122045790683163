import React, {useContext} from 'react'
import styles from '../styles/Header.module.css'
import {BuyNowCTA, Logo, LogoMark} from '../media/images'
import {LanguageContext} from "../../context";
import {HeaderEN, HeaderID} from '../data/LandingPageData'
import { css } from '@emotion/css';


export default function Header() {
    let props = ""
    const language = useContext(LanguageContext)
    if (language === "en-US")
        props = HeaderEN.cta
    else
        props = HeaderID.cta

    return (
        <div className={styles.header}>
            <img src={LogoMark} alt={"LogoMark"} className={styles.LogoMarkHeader}/>
            <img src={Logo} alt={Logo} className={styles.LogoHeader}/>
            <div className={styles.inlineblock}>
            <a href="https://shopee.co.id/bankachips" target="_blank">
                <img src={BuyNowCTA} alt={"Call to action"} className={styles.CallToAction}/></a>
            </div>
        </div>
    );
}