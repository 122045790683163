export const Logo = require('./Logo.png')
export const LogoMark = require('./Logomark.png')
export const Certificates = require('./Certificates.png')
export const CertificatesEN = require('./CertificatesEN.png')
export const Twirl = require('./Twirl.png')
export const YellowTwirl = require('./YellowTwirl.png')
export const Tiramisu = require('./Tiramisu.png')
export const Matcha = require('./Matcha.png')
export const Taro = require('./Taro.png');
export const Choco = require('./Choco.png')
export const Email = require('./Email.png')
export const EmailEN = require('./EmailEN.png')
export const WA = require('./WA.png');
export const WAEN = require('./WAEN.png');
export const Instagram = require('./Instagram.png')
export const InstagramEN = require('./InstagramEN.png')

export const AdOne = require('./AdOne.jpg')
export const AdsMain1 = require('./AdsMain1.png')
export const AdsMain2 = require('./AdsMain2.png')
export const AdThree = require('./AdThree.png')
export const BankaForest = require('./BankaForest.jpg')
export const BuyNowCTA = require('./BUYNOWCTA.png')
export const Market1 = require('./Market1.png')
export const Market2 = require('./Market2.png')